import React, { useRef, useLayoutEffect } from 'react';
import atomize from '@quarkly/atomize'; // Put your HTML here:

const customHtml = `<script src="//web.webformscr.com/apps/fc3/build/loader.js" async sp-form-id="810cf0db0ef3315d6319b5b30b298d7055920be15793a887c4890e5ec57e967a"></script>`;

const EmbedSendpulseForm = ({
	children,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = customHtml;
	}, []);
	return <div {...props} ref={ref} />;
};

export default atomize(EmbedSendpulseForm)({
	name: 'EmbedSendpulseForm',
	normalize: true,
	mixins: true
});