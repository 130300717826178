import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@quarkly/widgets';

const AccordionItem = ({
	isOpen: open,
	disabled,
	openItems,
	onToggleOpen,
	children,
	...rest
}) => {
	const [idx] = useState(`${Date.now() + Math.random()}`);
	const openIdx = openItems.includes(idx);
	const itemRef = useRef(null); // Создаём ref для отслеживания элемента

	const prevOpenIdx = useRef(openIdx); // Храним предыдущее состояние открытия

	useEffect(() => {
		if (open) {
			onToggleOpen({
				idx,
				open: !open,
				disabled
			});
		}
	}, []);
	useEffect(() => {
		if (openIdx && itemRef.current && !prevOpenIdx.current) {
			// Прокручиваем только при новом открытии элемента
			const offsetTop = itemRef.current.getBoundingClientRect().top + window.scrollY - 100; // Смещение на 100px выше

			window.scrollTo({
				top: offsetTop
			});
		} // Обновляем предыдущее состояние


		prevOpenIdx.current = openIdx;
	}, [openIdx]); // Эффект срабатывает при изменении статуса открытия

	return <Box flex-direction='column' display='flex' ref={itemRef} // Привязываем ref к элементу
	{...rest}>
		{React.Children.map(children, child => React.isValidElement(child) ? React.cloneElement(child, {
			idx,
			open: openIdx,
			disabled,
			onToggleOpen
		}) : child)}
	</Box>;
};

const propInfo = {
	isOpen: {
		control: 'checkbox',
		category: 'Main',
		weight: '1'
	},
	disabled: {
		control: 'checkbox',
		category: 'Main',
		weight: '1'
	}
};
const defaultProps = {
	open: true,
	disabled: false
};
export default Object.assign(AccordionItem, {
	propInfo,
	defaultProps
});